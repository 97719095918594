import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import '../assets/css/general.css';

const ProfilePage = () => {
  const { uniqueId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [courses, setCourses] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = firebase.auth().currentUser;
        const photoURL = currentUser ? currentUser.photoURL : null;

        const userQuery = await firebase
          .firestore()
          .collection('Users-Data')
          .where('uniqueId', '==', Number(uniqueId))
          .get();

        if (!userQuery.empty) {
          const userData = userQuery.docs[0].data();
          setUser({ ...userData, photoURL });

          // Fetch courses for the user
          const coursesRef = firebase.firestore().collection('Users-Data').doc(currentUser.uid).collection('Courses');
          const coursesSnapshot = await coursesRef.get();

          if (!coursesSnapshot.empty) {
            const coursesData = coursesSnapshot.docs.map(doc => doc.data());
            setCourses(coursesData);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const checkAuth = async () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          fetchUserData();
        } else {
          alert('You must be logged in to view this page.');
          navigate('/Account');
        }
      });
    };

    checkAuth();
  }, [uniqueId, navigate]);

  const handleImageUpload = async (e) => {
    setUploadingImage(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`profileImages/${uniqueId}`);

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      await firebase
        .firestore()
        .collection('Users-Data')
        .doc(uniqueId)
        .update({ photoURL: downloadURL });
      setUser((prevUser) => ({ ...prevUser, photoURL: downloadURL }));
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploadingImage(false);
    }
  };

  const defaultImageURL = 'https://cdn-icons-png.flaticon.com/128/9172/9172473.png';

  const redirectToCourseSelection = () => {
    if (user) {
      const username = user.displayName || 'User';
      navigate(`/User/Dashboard/Course/Select-course/${uniqueId}/${username}`);
    }
  };

  const handleCourseClick = (courseName) => {
    if (user) {
      const username = user.displayName || 'User';
      navigate(`/User/Course/Course-board/${courseName}/${uniqueId}/${username}`);
    }
  };

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      navigate('/'); 
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="ProfilePage">
      {user ? (
        <>
          <div className="ProfilePage-header">
            <img
              src={user.photoURL || defaultImageURL}
              alt="Profile"
              className="ProfilePage-avatar"
            />
            <div className="ProfilePage-info">
              <h2 className="ProfilePage-name">
                {user.fullName.split(' ').map((name) => name.charAt(0).toUpperCase() + name.slice(1)).join(' ')}
              </h2>
              <p className="ProfilePage-email">{user.email}</p>
              <input
                className="profile-update-image"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                disabled={uploadingImage}
              />
              {uploadingImage && <p>Uploading image...</p>}
            </div>
            <div className='logout'>
              <button className='logout-button' onClick={handleLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="currentColor" className="bi bi-circle-square" viewBox="0 0 16 16">
                  <path d="M0 6a6 6 0 1 1 12 0A6 6 0 0 1 0 6"/>
                  <path d="M12.93 5h1.57a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1.57a7 7 0 0 1-1-.22v1.79A1.5 1.5 0 0 0 5.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 4h-1.79q.145.486.22 1"/>
                </svg> Logout
              </button>

              <hr className="ProfilePage-divider" />
     
              <br />
              <br />
              <button className='course-select' onClick={redirectToCourseSelection}>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-journals" viewBox="0 0 16 16">
          <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2"/>
          <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0"/>
        </svg> Find Course
      </button>
            </div>
          </div>
          <br />
          <hr className="coursestaken ProfilePage-divider" />
        </>
      ) : (
        <p>Loading user data...</p>
      )}
      <div className="coursestaken ProfilePage-courses">
        <h3>Courses Enrolled In</h3>
        {courses.length === 0 ? (
          <p>No courses taken yet.</p>
        ) : (
          <div className="courses-grid">
            {courses.map((course, index) => (
              <div 
                key={index} 
                className="course-card" 
                onClick={() => handleCourseClick(course.courseName)}
              >
                <h4>{course.courseName}</h4>
                <p>Commenced on: {new Date(course.dateCommenced).toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        )}
      </div> 
    </div>
  );
};

export default ProfilePage;
