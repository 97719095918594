import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const OnboardPage = () => {
  const { uniqueId, uid } = useParams();
  const { state } = useLocation();
  const displayName = state.fullName.toUpperCase();

  const handleAdmissionClick = () => {
    // Redirect to /User/Admissions/:uniqueId/:uid with state
    window.location.href = `/User/Admissions/${uniqueId}/${uid}`;
  };

  const handleScholarshipClick = () => {
    // Redirect to /User/Scholarship/:uniqueId/:uid with state
    window.location.href = `/User/Scholarship/${uniqueId}/${uid}`;
  };

  return (
    <div className="gen-onboard-pre">
      <header>Welcome, {displayName}</header>
      <div className="onboard-pre-board">
        <div className="column">
          <h2>Admission</h2>
          <button onClick={handleAdmissionClick}>Go to Admission</button>
        </div>
        <div className="column">
          <h2>Scholarship</h2>
          <button onClick={handleScholarshipClick}>Go to Scholarship</button>
          <br />
          <p>
            <a
              href="https://lecturemeet.com/scholarship"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read Guidelines
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardPage;
