import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const ScholarshipPage = () => {
    const { uniqueId, uid } = useParams();
    const [formData, setFormData] = useState({
        surname: '',
        firstName: '',
        otherNames: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        gender: '',
        nationality: 'Nigeria',
        contactInformation: {
            address: '',
            parentGuardianPhoneNumber: '',
            parentGuardianEmail: ''
        },
        educationalBackground: {
            schoolName: '',
            yearOfCompletion: '',
            jambScore: ''
        },
        financialInformation: {
            incomeLevel: '',
            proofOfFinancialNeed: ''
        },
        proofOfNigerianCitizenship: null,
        preDegreeProgramSelection: '',
        resultsUpload: null,
        subjects: [{ name: '', grade: '' }],
        letterOfRecommendation: null,
        personalStatement: '',
        confirmationOfAge: null,
        commitmentStatementToStudy: '',
        additionalSupportingDocuments: []
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [mainKey, subKey] = name.split('.');
        if (subKey) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [mainKey]: {
                    ...prevFormData[mainKey],
                    [subKey]: value
                }
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'additionalSupportingDocuments') {
            setFormData({ ...formData, [name]: [...files] });
        } else {
            setFormData({ ...formData, [name]: files[0] });
        }
    };

    const handleSubjectChange = (index, field, value) => {
        const newSubjects = formData.subjects.map((subject, i) => (
            i === index ? { ...subject, [field]: value } : subject
        ));
        setFormData({ ...formData, subjects: newSubjects });
    };

    const handleAddSubject = () => {
        setFormData({ ...formData, subjects: [...formData.subjects, { name: '', grade: '' }] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Include uniqueId and uid in the submission data
        const submissionData = {
            ...formData,
            uniqueId,
            uid
        };
        console.log('Submitting form data:', submissionData);
        // Handle form submission, e.g., submit data to backend
    };

    return (
        <div className="scholarship-page">
            <h2>Scholarship Application Form</h2>
            <p>Unique ID: {uniqueId}</p>
            <p>User ID: {uid}</p>
            <form onSubmit={handleSubmit}>
                {/* Surname, First name, other names */}
                <input type="text" name="surname" placeholder="Surname" value={formData.surname} onChange={handleInputChange} />
                <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleInputChange} />
                <input type="text" name="otherNames" placeholder="Other Names" value={formData.otherNames} onChange={handleInputChange} />
                {/* Email, Phone number */}
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} />
                <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleInputChange} />
                {/* Date of Birth, Gender */}
                <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange} />
                <select name="gender" value={formData.gender} onChange={handleInputChange}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
                {/* Nationality */}
                <p>Nationality: {formData.nationality}</p>
                {/* Contact Information */}
                <input type="text" name="contactInformation.address" placeholder="Address" value={formData.contactInformation.address} onChange={handleInputChange} />
                <input type="tel" name="contactInformation.parentGuardianPhoneNumber" placeholder="Parent/Guardian Phone Number" value={formData.contactInformation.parentGuardianPhoneNumber} onChange={handleInputChange} />
                <input type="email" name="contactInformation.parentGuardianEmail" placeholder="Parent/Guardian Email" value={formData.contactInformation.parentGuardianEmail} onChange={handleInputChange} />
                {/* Educational Background */}
                <input type="text" name="educationalBackground.schoolName" placeholder="School Name" value={formData.educationalBackground.schoolName} onChange={handleInputChange} />
                <input type="text" name="educationalBackground.yearOfCompletion" placeholder="Year of Completion" value={formData.educationalBackground.yearOfCompletion} onChange={handleInputChange} />
                <input type="text" name="educationalBackground.jambScore" placeholder="JAMB Score" value={formData.educationalBackground.jambScore} onChange={handleInputChange} />
                {/* Financial Information */}
                <input type="text" name="financialInformation.incomeLevel" placeholder="Income Level" value={formData.financialInformation.incomeLevel} onChange={handleInputChange} />
                <input type="text" name="financialInformation.proofOfFinancialNeed" placeholder="Proof of Financial Need" value={formData.financialInformation.proofOfFinancialNeed} onChange={handleInputChange} />
                {/* Proof of Nigerian Citizenship */}
                <input type="file" name="proofOfNigerianCitizenship" onChange={handleFileChange} />
                {/* Pre-Degree Program Selection */}
                <select name="preDegreeProgramSelection" value={formData.preDegreeProgramSelection} onChange={handleInputChange}>
                    <option value="">Select Program</option>
                    <option value="law">Law</option>
                    <option value="massCommunication">Mass Communication</option>
                    <option value="businessAdministration">Business Administration</option>
                </select>
                {/* Results Upload */}
                <input type="file" name="resultsUpload" onChange={handleFileChange} />
                {/* Subjects */}
                <br />
                <br />
                <h2>Subjects and Grades</h2>
                {formData.subjects.map((subject, index) => (
                    <div key={index}>
                        <input type="text" value={subject.name} onChange={(e) => handleSubjectChange(index, 'name', e.target.value)} placeholder="Subject Name" />
                        <input type="text" value={subject.grade} onChange={(e) => handleSubjectChange(index, 'grade', e.target.value)} placeholder="Grade" />
                    </div>
                ))}
                <button type="button" onClick={handleAddSubject}>Add Subject</button>
                {/* Letter of Recommendation */}
                <input type="file" name="letterOfRecommendation" onChange={handleFileChange} />
                {/* Personal Statement */}
                <textarea name="personalStatement" placeholder="Personal Statement" value={formData.personalStatement} onChange={handleInputChange}></textarea>
                {/* Confirmation of Age */}
                <input type="file" name="confirmationOfAge" onChange={handleFileChange} />
                {/* Commitment Statement to Study */}
                <textarea name="commitmentStatementToStudy" placeholder="Commitment Statement to Study" value={formData.commitmentStatementToStudy} onChange={handleInputChange}></textarea>
                {/* Additional Supporting Documents */}
                <input type="file" name="additionalSupportingDocuments" multiple onChange={handleFileChange} />
                {/* Submit button */}
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ScholarshipPage;
