import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Account Components
import Account from './Account/Account';
import AdminLogin from './Account/AdminLogin';
import Oauth from './Account/Oauth';

// User Components
import Dashboard from './User/Dashboard';
import Confirmation from './User/Dashboard/Confirmation';
import Profile from './User/Profile';
import Admissions from './User/Dashboard/Admissions';
import Scholarship from './User/Scholarship';
import Onboard from './User/Onboard';
import Admissionapplicationpayments from './User/Admission-application-payments';
import Status from './User/AdminApproval/Status';
import ViewDetails from './User/AdminApproval/ViewDetails';
import Admissionstatus from './User/Dashboard/Admission-status';
import Payment from './User/Dashboard/Payment';
import Selectcourse from './User/Dashboard/Course/Select-course';
import Courseboard from './User/Dashboard/Course/Course-board';
import NotFound from './User/NotFound';

// Admin Components
import Admin from './Admin/Admin';
import AdminCourseUpload from './Admin/AdminCourseUpload';

// Authentication Wrapper
import RequireAuth from './RequireAuth';

function AppRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Account />} />
      <Route path="/Account" element={<Account />} />
      <Route path="/Account/AdminLogin" element={<AdminLogin />} />
      
      <Route
        path="/User/AdminApproval/Status/:AdminuniqueId/:uid"
        element={
          <RequireAuth>
            <Status />
          </RequireAuth>
        }
      />

<Route
        path="/User/AdminApproval/ViewDetails/:uniqueId/:id"
        element={
          <RequireAuth>
            <ViewDetails />
          </RequireAuth>
        }
      />

<Route
        path="/User/Scholarship/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Scholarship />
          </RequireAuth>
        }
      />

<Route
        path="/User/Dashboard/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />

<Route
        path="/Account/OAuth/:uniqueId"
        element={
          <RequireAuth>
            <Oauth />
          </RequireAuth>
        }
      />


      <Route
        path="/User/Admissions/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Admissions />
          </RequireAuth>
        }
      />

<Route
        path="/User/Onboard/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Onboard />
          </RequireAuth>
        }
      />



<Route
        path="/User/Admission-application-payments/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Admissionapplicationpayments />
          </RequireAuth>
        }
      />
      <Route
        path="/User/Dashboard/Admission-status/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Admissionstatus />
          </RequireAuth>
        }
      />
<Route
        path="/User/Dashboard/Payment/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Payment />
          </RequireAuth>
        }
      />
<Route
        path="/User/Dashboard/Confirmation/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Confirmation />
          </RequireAuth>
        }
      />

      <Route
        path="/User/Profile/:uniqueId/:uid"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
     

      <Route
        path="/User/Dashboard/Course/Select-course/:uniqueId/:username"
        element={
          <RequireAuth>
            <Selectcourse />
          </RequireAuth>
        }
      />
      <Route
        path="/User/Course/Course-board/:courseName/:uniqueId/:userName"
        element={
          <RequireAuth>
            <Courseboard />
          </RequireAuth>
        }
      />
      
      <Route path="/User/AdminCourseUpload" element={<AdminCourseUpload />} />
      <Route path="/Admin/Admin" element={<Admin />} />
      <Route path="/Admin/AdminCourseUpload" element={<AdminCourseUpload />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
