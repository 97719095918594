/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const AdminApprovalStatusPage = () => {
  const { uniqueId, uid } = useParams();
  const navigate = useNavigate();
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const snapshot = await firebase.firestore().collection('Pre-Degree-Users-Data').get();
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRegistrations(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching registrations:', error);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  const handleStatusChange = async (id, status, comment = '') => {
    try {
      await firebase.firestore().collection('Pre-Degree-Users-Data').doc(id).update({
        admissionStatus: status,
        adminComment: comment
      });
      // Notify the user via email or in-app notification if necessary
      // Fetch the updated list
      const updatedList = registrations.map(reg =>
        reg.id === id ? { ...reg, admissionStatus: status, adminComment: comment } : reg
      );
      setRegistrations(updatedList);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleViewDetails = (id) => {
    navigate(`/User/AdminApproval/ViewDetails/${uniqueId}/${id}`);
  };

  return (
    <div className="admin-approval-status-container">
      <h1>Admin Approval Status</h1>
      {loading ? (
        <p>Loading registrations...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Unique ID</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {registrations.map(registration => (
              <tr key={registration.id}>
                <td>{registration.name}</td>
                <td>{registration.email}</td>
                <td>{registration.uniqueId}</td>
                <td>{registration.admissionStatus || 'Pending'}</td>
                <td>
                  <button onClick={() => handleViewDetails(registration.id)}>View Details</button>
                  <button onClick={() => handleStatusChange(registration.id, 'Accepted')}>Accept</button>
                  <button onClick={() => handleStatusChange(registration.id, 'Rejected', prompt('Enter rejection reason:'))}>Reject</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminApprovalStatusPage;
