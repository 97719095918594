import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const AdmissionStatusPage = () => {
  const { uniqueId, uid } = useParams();
  const navigate = useNavigate();
  const [admissionStatus, setAdmissionStatus] = useState('');
  const [adminComment, setAdminComment] = useState('');

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const doc = await firebase.firestore().collection('Pre-Degree-Users-Data').doc(uid).get();
        if (doc.exists) {
          setAdmissionStatus(doc.data().admissionStatus);
          setAdminComment(doc.data().adminComment);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching admission status:', error);
      }
    };

    fetchStatus();
  }, [uid]);

  const handleProceedToPay = () => {
    navigate(`/User/Dashboard/Payment/${uniqueId}/${uid}`);
  };

  return (
    <div className="admission-status-container">
      <h1>Admission Status</h1>
      <p>Unique ID: {uniqueId}</p>
      <p>Status: {admissionStatus || 'Pending'}</p>
      {adminComment && <p>Admin Comment: {adminComment}</p>}
      {admissionStatus === 'Accepted' && (
        <button onClick={handleProceedToPay} className="admission-status-button">Proceed to Pay Fees</button>
      )}
    </div>
  );
};

export default AdmissionStatusPage;
