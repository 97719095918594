import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import PaystackPop from '@paystack/inline-js';

const PaymentPage = () => {
  const { uniqueId, uid } = useParams();
  const navigate = useNavigate();
  const [selectedFee, setSelectedFee] = useState('');
  const [installment, setInstallment] = useState(false);
  const [programCourse, setProgramCourse] = useState('');

  const handleFeeChange = (event) => {
    setSelectedFee(event.target.value);
    setInstallment(event.target.value === '68000');
  };

  const handleProgramCourseChange = (event) => {
    setProgramCourse(event.target.value);
  };

  const handlePayment = () => {
    if (!selectedFee) {
      alert('Please select a payment amount.');
      return;
    }

    if (!programCourse) {
      alert('Please select a Pre-Degree Program Course.');
      return;
    }

    const db = firebase.firestore();
    const paystack = new PaystackPop();
    const amount = parseInt(selectedFee, 10);

    const nextPaymentDate = new Date();
    nextPaymentDate.setDate(nextPaymentDate.getDate() + 30);

    const authenticatedUser = firebase.auth().currentUser;
    const userName = authenticatedUser.displayName;
    const userEmail = authenticatedUser.email;
    const referenceNumber = Math.floor(Math.random() * 1008096432134507);

    const paymentData = {
      name: userName,
      email: userEmail,
      uniqueId: uniqueId,
      referenceNumber: referenceNumber,
      preDegreeProgram: programCourse,
      amount: amount,
      paymentOption: installment ? 'Installment' : 'Full Payment',
    };

    // Save payment details to Firestore
    db.collection('Pre-Degree-Users-Data').doc(uid).collection('Pre-degree-ApplicantPayment').add(paymentData)
      .then(() => {
        console.log('Payment details saved successfully');
        paystack.newTransaction({
          key: 'pk_live_9eef972d8706d0d5069ed6f3122f96289654d5c0',
          email: userEmail,
          amount: amount * 100,
          metadata: {
            custom_fields: [
              {
                display_name: 'Unique ID',
                variable_name: 'unique_id',
                value: uniqueId,
              },
              {
                display_name: 'Pre-Degree Program',
                variable_name: 'pre_degree_program',
                value: programCourse,
              },
            ],
          },
          callback: (response) => {
            console.log('Payment successful:', response);
            if (installment) {
              console.log('Next installment payment date:', nextPaymentDate.toDateString());
            }
            navigate(`/User/Dashboard/${uniqueId}/${uid}`);
          },
          onClose: () => {
            console.log('Payment closed');
          },
        });
      })
      .catch((error) => {
        console.error('Error saving payment details:', error);
      });
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <h1>Payment Options</h1>
      <p>Unique ID: {uniqueId}</p>
      
      <div style={{ marginBottom: '20px' }}>
        <label>
          Payment Amount:
          <select value={selectedFee} onChange={handleFeeChange} style={{ marginLeft: '10px', padding: '5px' }}>
            <option value="">Select an amount</option>
            <option value="98000">₦98,000 (Without learning Kits)</option>
            <option value="208000">₦208,000 (Full payment inclusive of learning kits)</option>
            <option value="68000">₦68,000  (Pay in 3 Installments - Autocharged monthly)</option>
          </select>
        </label>
      </div>
      <div style={{ marginTop: '20px' }}>
        <label>
          Pre-Degree Program Course:
          <select value={programCourse} onChange={handleProgramCourseChange} style={{ marginLeft: '10px', padding: '5px' }}>
            <option value="">Select a course</option>
            <option value="Law">Law</option>
            <option value="Mass Communication">Mass Communication</option>
            <option value="Business Administration">Business Administration</option>
          </select>
        </label>
      </div>
      <button
        style={{
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          marginTop: '20px',
        }}
        onClick={handlePayment}
      >
        Proceed to Payment
      </button>
    </div>
  );
};

export default PaymentPage;
