import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import PaystackPop from '@paystack/inline-js';

const AdmissionApplicationPayments = () => {
    const { uniqueId, uid } = useParams();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        username: '',
        email: ''
    });
    const [paymentStatus, setPaymentStatus] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user = firebase.auth().currentUser;
                if (user) {
                    const userDoc = await firebase.firestore().collection('Pre-degree-Applicant').doc(user.uid).get();
                    if (userDoc.exists) {
                        const { username, email } = userDoc.data();
                        setUserData({ username, email });
                    } else {
                        console.error('User not found in Firestore');
                    }
                } else {
                    console.error('No authenticated user');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const handlePayment = () => {
        if (!userData.email || !userData.username) {
            setPaymentStatus('User data is incomplete.');
            return;
        }

        try {
            const paystack = new PaystackPop();
            paystack.newTransaction({
                key: 'pk_live_9eef972d8706d0d5069ed6f3122f96289654d5c0',
                email: userData.email,
                amount: 15000 * 100, // Amount in kobo
                metadata: {
                    custom_fields: [
                        {
                            display_name: "Username",
                            variable_name: "username",
                            value: userData.username
                        }
                    ]
                },
                onSuccess: async (transaction) => {
                    setPaymentStatus('Payment successful! Reference: ' + transaction.reference);
                    try {
                        // Save payment information to Firestore
                        await firebase.firestore().collection('Pre-Degree-Users-Data').doc(uid).collection('Payments').add({
                            reference: transaction.reference,
                            status: 'success',
                            amount: 15000,
                            title: 'Application Fee for Pre-Degree Admission',
                            date: new Date()
                        });
                        navigate(`/User/AdminApproval/Status/${uniqueId}/${uid}`);
                    } catch (error) {
                        console.error('Error saving payment details:', error);
                        setPaymentStatus('Payment was successful but an error occurred while saving payment details.');
                    }
                },
                onCancel: () => {
                    setPaymentStatus('Payment was cancelled.');
                },
                onError: (error) => {
                    console.error('Payment error:', error);
                    setPaymentStatus('An error occurred during payment. Please try again.');
                }
            });
        } catch (error) {
            console.error('Error initializing Paystack payment:', error);
            if (error.issues) {
                console.error('Issues:', error.issues);
            }
            setPaymentStatus('An error occurred during payment initialization. Please try again.');
        }
    };

    return (
        <div className="payment-container">
            <h1>Admission Application Payment</h1>
            <p>Username: {userData.username}</p>
            <p>Email: {userData.email}</p>
            <p>Amount: ₦15,000</p>
            <button onClick={handlePayment}>Pay Now</button>
            {paymentStatus && <p>{paymentStatus}</p>}
        </div>
    );
};

export default AdmissionApplicationPayments;
