import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
const AdmissionsPage = () => {
    const { uniqueId, uid } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const fullName = location.state && location.state.fullName ? location.state.fullName : '';
    const [formStep, setFormStep] = useState(1);
    const [formData, setFormData] = useState({
        surname: '',
        firstName: '',
        otherNames: '',
        phoneNumber: '',
        country: '',
        gender: '',
        program: '',
        parentName: '',
        guardianName: '',
        sponsorName: '',
        applicantAddress: '',
        sponsorAddress: '',
        type: '',
        scholarshipDocs: [],
        profilePicture: null,
        schoolName: '',
        graduationYear: '',
        waecResult: null,
        necoResult: null,
        jambScore: '',
        jambResult: null,
        subjects: [{ name: '', grade: '' }],
        learningKit: '',
    });

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData({ ...formData, [name]: files });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubjectChange = (index, field, value) => {
        const newSubjects = formData.subjects.map((subject, i) => (
            i === index ? { ...subject, [field]: value } : subject
        ));
        setFormData({ ...formData, subjects: newSubjects });
    };

    const handleAddSubject = () => {
        setFormData({ ...formData, subjects: [...formData.subjects, { name: '', grade: '' }] });
    };

    const handleNextStep = () => {
        setFormStep(formStep + 1);
    };

    const handlePrevStep = () => {
        setFormStep(formStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        const requiredFields = ['surname', 'firstName', 'phoneNumber', 'country', 'gender', 'program', 'parentName', 'guardianName', 'sponsorName', 'applicantAddress', 'sponsorAddress', 'type', 'schoolName', 'graduationYear', 'waecResult', 'necoResult', 'jambScore', 'jambResult'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                alert(`Please fill out the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} field.`);
                return;
            }
        }

        try {
            // Upload the profile picture if present
            let profilePictureURL = '';
            if (formData.profilePicture) {
                const storageRef = firebase.storage().ref();
                const profilePictureRef = storageRef.child(`profilePictures/${uid}`);
                await profilePictureRef.put(formData.profilePicture[0]);
                profilePictureURL = await profilePictureRef.getDownloadURL();
            }

            // Upload scholarship documents if present
            let scholarshipDocsURLs = [];
            if (formData.type === 'scholarship' && formData.scholarshipDocs.length > 0) {
                const storageRef = firebase.storage().ref();
                const uploadPromises = Array.from(formData.scholarshipDocs).map(async (file, index) => {
                    const docRef = storageRef.child(`scholarshipDocs/${uid}_${index}`);
                    await docRef.put(file);
                    return await docRef.getDownloadURL();
                });
                scholarshipDocsURLs = await Promise.all(uploadPromises);
            }

            await firebase.firestore().collection('Pre-Degree-Users-Data').doc(uid).collection('Admissions').add({
                ...formData,
                profilePicture: profilePictureURL,
                scholarshipDocs: scholarshipDocsURLs,
                status: 'Pending',
            });

            // Reset form data
            setFormData({
                surname: '',
                firstName: '',
                otherNames: '',
                phoneNumber: '',
                country: '',
                gender: '',
                program: '',
                parentName: '',
                guardianName: '',
                sponsorName: '',
                applicantAddress: '',
                sponsorAddress: '',
                type: '',
                scholarshipDocs: [],
                profilePicture: null,
                schoolName: '',
                graduationYear: '',
                waecResult: null,
                necoResult: null,
                jambScore: '',
                jambResult: null,
                subjects: [{ name: '', grade: '' }],
                learningKit: '',
            });

            navigate(`/User/Admission-application-payments/${uniqueId}/${uid}`);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const capitalize = (s) => s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : '';

    const displayFullName = fullName.split(' ').map(capitalize).join(' ');

    return (
        <div className="Predgree-onobaording">
            <h2>Welcome To Admissions {displayFullName}</h2>

            <hr />
            <br />
            <br />
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {formStep === 1 && (
                        <div className="board-pre">
                            <label className="onboading-pre-degree">Surname</label>
                            <input type="text" name="surname" value={formData.surname} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">First Name</label>
                            <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Other Names</label>
                            <input type="text" name="otherNames" value={formData.otherNames} onChange={handleInputChange} />
                            <label className="onboading-pre-degree">Phone Number</label>
                            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Country</label>
                            <input type="text" name="country" value={formData.country} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Gender</label>
                            <select name="gender" value={formData.gender} onChange={handleInputChange} required>
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <label className="onboading-pre-degree">Program</label>
                            <select name="program" value={formData.program} onChange={handleInputChange} required>
                                <option value="">Select Program</option>
                                <option value="law">Law</option>
                                <option value="mass communication">Mass Communication</option>
                                <option value="business administration">Business Administration</option>
                            </select>
                            <label className="onboading-pre-degree">Parent's Name</label>
                            <input type="text" name="parentName" value={formData.parentName} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Guardian's Name</label>
                            <input type="text" name="guardianName" value={formData.guardianName} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Sponsor's Name</label>
                            <input type="text" name="sponsorName" value={formData.sponsorName} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Applicant's Address</label>
                            <input type="text" name="applicantAddress" value={formData.applicantAddress} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Sponsor's Address</label>
                            <input type="text" name="sponsorAddress" value={formData.sponsorAddress} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Type</label>
                            <select name="type" value={formData.type} onChange={handleInputChange} required>
                                <option value="">Select Type</option>
                                <option value="paid">Paid</option>
                                <option value="scholarship">Scholarship</option>
                            </select>
                            {formData.type === 'scholarship' && (
                                <>
                                    <label className="onboading-pre-degree">Upload Scholarship Documents</label>
                                    <input type="file" name="scholarshipDocs" multiple onChange={handleInputChange} required />
                                </>
                            )}
                            <label className="onboading-pre-degree">Upload Profile Picture</label>
                            <input type="file" name="profilePicture" onChange={handleInputChange} />
                            <button type="button" onClick={handleNextStep}>Next</button>
                        </div>
                    )}

                    {formStep === 2 && (
                        <div className="board-pre">
                            <label className="onboading-pre-degree">Name of Secondary School</label>
                            <input type="text" name="schoolName" value={formData.schoolName} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Year of Graduation</label>
                            <input type="text" name="graduationYear" value={formData.graduationYear} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Upload WAEC Result</label>
                            <input type="file" name="waecResult" onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Upload NECO Result</label>
                            <input type="file" name="necoResult" onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">JAMB Score</label>
                            <input type="text" name="jambScore" value={formData.jambScore} onChange={handleInputChange} required />
                            <label className="onboading-pre-degree">Upload JAMB Result</label>
                            <input type="file" name="jambResult" onChange={handleInputChange} required />
                            <div className="subject-grades">
                                {formData.subjects.map((subject, index) => (
                                    <div key={index} className="subject-grade">
                                        <label className="onboading-pre-degree">Subject</label>
                                        <input
                                            type="text"
                                            value={subject.name}
                                            onChange={(e) => handleSubjectChange(index, 'name', e.target.value)}
                                            required
                                        />
                                        <label className="onboading-pre-degree">Grade</label>
                                        <input
                                            type="text"
                                            value={subject.grade}
                                            onChange={(e) => handleSubjectChange(index, 'grade', e.target.value)}
                                            required
                                        />
                                    </div>
                                ))}
                                <button type="button" onClick={handleAddSubject}>+</button>
                            </div>
                            <button type="button" onClick={handlePrevStep}>Previous</button>
                            <button type="button" onClick={handleNextStep}>Next</button>
                        </div>
                    )}

                    {formStep === 3 && (
                        <div className="board-pre">
                            <label className="onboading-pre-degree">Do you need a Learning Kit?</label>
                            <select name="learningKit" value={formData.learningKit} onChange={handleInputChange}>
                                <option value="">Select Option</option>
                                <option value="yes">Yes - + Fees ₦189,000 (Wifi, Laptop and learning kits)</option>
                                <option value="no">I have learning tools ₦75,000</option>
                            </select>
                            <button type="button" onClick={handlePrevStep}>Previous</button>
                            <button type="submit">Submit</button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default AdmissionsPage;
