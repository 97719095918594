import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/Courseboard.css';

const CourseDetails = () => {
    const { courseName } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const uniqueId = useParams().uniqueId;
    const userName = new URLSearchParams(location.search).get('userName');
    const userEmail = new URLSearchParams(location.search).get('userEmail');
    const userId = `${uniqueId}_${userName}`;

    const [course, setCourse] = useState(null);
    const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
    const [completedModules, setCompletedModules] = useState([]);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(true);
    const [isReading, setIsReading] = useState(false);
    const [fullName, setFullName] = useState('');

    const speechSynthesisRef = useRef(window.speechSynthesis);
    const utteranceRef = useRef(null);
    console.log('userName:', userName);
    console.log('Route params:', useParams());

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    setFullName(currentUser.displayName || '');
                }
            } catch (error) {
                console.error('Error fetching user data: ', error);
                toast.error('Error fetching user data');
            }
        };

        const fetchCourseData = async () => {
            try {
                const db = getFirestore();
                const courseRef = doc(db, 'Courses', courseName.toLowerCase().replace(/\s+/g, '-'));
                const courseSnap = await getDoc(courseRef);

                if (courseSnap.exists()) {
                    setCourse(courseSnap.data());
                } else {
                    console.error('No such course!');
                    toast.error('Course not found!');
                }
            } catch (error) {
                console.error('Error fetching course data: ', error);
                toast.error('Error fetching course data');
            } finally {
                setLoading(false);
            }
        };

        const fetchUserProgress = async () => {
            try {
                const db = getFirestore();
                const progressRef = doc(db, 'PayPerCourseUserProgress', userId);
                const progressSnap = await getDoc(progressRef);

                if (progressSnap.exists()) {
                    const data = progressSnap.data();
                    setCurrentModuleIndex(data.currentModuleIndex || 0);
                    setCompletedModules(data.completedModules || []);
                }
            } catch (error) {
                console.error('Error fetching user progress: ', error);
                toast.error('Error fetching user progress');
            }
        };

        fetchUserData();
        fetchCourseData();
        fetchUserProgress();

        const speechSynthesis = speechSynthesisRef.current;
        return () => {
            if (utteranceRef.current) {
                speechSynthesis.cancel();
            }
        };
    }, [courseName, userId]);

    const handleMarkComplete = async () => {
        if (currentModuleIndex >= course.modules.length - 1) {
            toast.success('All modules completed!');
            setCompletedModules([...completedModules, currentModuleIndex]);
            return;
        }

        const newCompletedModules = [...completedModules, currentModuleIndex];
        setCompletedModules(newCompletedModules);
        const newModuleIndex = currentModuleIndex + 1;
        setCurrentModuleIndex(newModuleIndex);
        toast.success('Module marked as complete!');

        try {
            const db = getFirestore();
            const progressRef = doc(db, 'PayPerCourseUserProgress', userId);
            await setDoc(progressRef, { 
                currentModuleIndex: newModuleIndex, 
                completedModules: newCompletedModules 
            }, { merge: true });
        } catch (error) {
            console.error('Error saving user progress: ', error);
            toast.error('Error saving user progress');
        }
    };

    const handleFeedbackSubmit = async () => {
        try {
            const db = getFirestore();
            const feedbackRef = doc(db, 'PayPerCourseFeedback', `${courseName.toLowerCase().replace(/\s+/g, '-')}_${userId}`);
            await setDoc(feedbackRef, {
                userId,
                fullName, // Use the authenticated user's full name
                uniqueId,
                userEmail,
                courseName,
                feedback,
                timestamp: new Date().toISOString()
            });
            toast.success('Feedback submitted successfully!');
            setFeedback('');
        } catch (error) {
            console.error('Error submitting feedback: ', error);
            toast.error('Error submitting feedback');
        }
    };

    const handleRead = () => {
        if (!utteranceRef.current) {
            const textToRead = course.modules[currentModuleIndex].topics.map(topic => `${topic.title}. ${topic.content}`).join(' ');
            utteranceRef.current = new SpeechSynthesisUtterance(textToRead);
            utteranceRef.current.lang = 'en-US'; // Adjust language as needed
            utteranceRef.current.rate = 0.9;
            utteranceRef.current.onend = () => setIsReading(false);
        }

        if (isReading) {
            speechSynthesisRef.current.pause();
        } else {
            speechSynthesisRef.current.speak(utteranceRef.current);
        }
        setIsReading(!isReading);
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            const speechSynthesis = speechSynthesisRef.current;
            if (document.hidden && isReading) {
                speechSynthesis.pause();
            } else if (!document.hidden && isReading) {
                speechSynthesis.resume();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isReading]);

    const handleGetCertificate = async () => {
        const userNameFromFullName = fullName.split(' ')[0]; // Extract the first name as the username
        console.log('fullName:', fullName);
        console.log('userNameFromFullName:', userNameFromFullName);
        if (!userNameFromFullName) {
            toast.error('User name is not available');
            return;
        }
    
        const certificateNumber = `${userNameFromFullName.substring(0, 5)}-${Math.random().toString(36).substring(2, 8)}-${userNameFromFullName.slice(-3)}`;
    
        try {
            const db = getFirestore();
            const certificateRef = doc(db, 'PayPerCourse-Learners', `${uniqueId}_${userNameFromFullName}`);
    
            const certificateSnap = await getDoc(certificateRef);
            if (certificateSnap.exists()) {
                // If the certificate number already exists, recursively call handleGetCertificate again
                return handleGetCertificate();
            }
    
            await setDoc(certificateRef, {
                certificateNumber,
                fullName: fullName, // Include the authenticated user's full name
                courseName,
                uniqueId,
                timestamp: new Date().toISOString()
            });
    
            // Navigate to the certificate page after successfully generating the certificate
            navigate(`/User/Dashboard/Course/Certificate/${uniqueId}/${courseName}/${userNameFromFullName}`, {
                state: {
                    course,
                    uniqueId,
                    fullName: fullName
                }
            });
        } catch (error) {
            console.error('Error generating certificate: ', error);
            toast.error('Error generating certificate');
        }
    };
    
    
    

    const handleGoBack = () => {
        navigate(`/User/Profile/${uniqueId}/${userName}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!course) {
        return <div>Course not found</div>;
    }

    return (
        <div className="container-course-board">
            <ToastContainer className="toastify-container" />
            <div className="course-details">           
                <h2>{course.courseTitle}</h2>
                <h3>Modules</h3>
                <div>
                    {course.modules.map((module, index) => (
                        <div
                            key={module.moduleId}
                            style={{
                                display: index === currentModuleIndex ? 'block' : 'none',
                            }}
                        >
                            <h4>{module.moduleName}</h4>
                            <ul>
                                {module.topics.map((topic, topicIndex) => (
                                    <li key={topicIndex}>
                                        <h5>{topic.title}</h5>
                                        <p>{topic.content.split('\n').map((str, i) => <span key={i}>{str}<br /></span>)}</p>
                                    </li>
                                ))}
                            </ul>
                            {completedModules.includes(index) ? (
                                <p>Module Completed</p>
                            ) : (
                                <button onClick={handleMarkComplete}>Mark as Complete</button>
                            )}
                        </div>
                    ))}
                </div>
    
                {completedModules.length === course.modules.length && (
                    <button onClick={handleGetCertificate}>Get Certificate</button>
                )}
            </div>
            <div className="user-feedback">
                <h2>Welcome, {fullName.split(' ').map((name) => name.charAt(0).toUpperCase() + name.slice(1)).join(' ')}</h2>
                <h4>Your Progress:</h4>
                <p>{`You have completed ${completedModules.length} out of ${course.modules.length} modules.`}</p>
                <h4>Leave a review on what you think about the course</h4>
                <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Write your review here..."
                ></textarea>
                <button onClick={handleFeedbackSubmit}>Submit Review</button>
                <br />
    
                <button className='read' onClick={handleRead}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-volume-up-fill" viewBox="0 0 16 16">
                        <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z" />
                        <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.708.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.608 3.889l.707.707z" />
                        <path d="M8.707 11.889A4.48 4.48 0 0 0 10.025 8a4.48 4.48 0 0 0-1.318-3.889l-.707.707A3.48 3.48 0 0 1 9.025 8a3.48 3.48 0 0 1-1.025 2.475l.707.707z" />
                        <path d="M8 12a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-1 0v7.5a.5.5 0 0 0 .5.5z" />
                        <path d="M6.5 10H4A1.5.5 0 0 1 1.5 8.5v-1A1.5.5 0 0 1 4 6h2.5v4z" />
                    </svg>
                </button>
<br />
<br />
                <button className='bg-black text-white' onClick={handleGoBack}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708z"/>
</svg>
                </button>
            </div>
        </div>
    );
};

export default CourseDetails;