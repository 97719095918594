import React from 'react';
import { useLocation } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const paymentDetails = location.state?.paymentDetails;

  return (
    <div style={{ padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <h1>Payment Confirmation</h1>
      {paymentDetails && (
        <div>
          <p>Name: {paymentDetails.name}</p>
          <p>Email: {paymentDetails.email}</p>
          <p>Unique ID: {paymentDetails.uniqueId}</p>
          <p>Reference Number: {paymentDetails.referenceNumber}</p>
          <p>Pre-Degree Program: {paymentDetails.preDegreeProgram}</p>
          <p>Amount Paid: ₦{paymentDetails.amount}</p>
          <p>Payment Option: {paymentDetails.paymentOption}</p>
        </div>
      )}
    </div>
  );
};

export default ConfirmationPage;
