import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import logo from '../assets/images/Lecturemeet.png';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDqOJIIVMikxNfvkMD68lz0TkODpcLhjtQ",
  authDomain: "business-lecturemeet.firebaseapp.com",
  projectId: "business-lecturemeet",
  storageBucket: "business-lecturemeet.appspot.com",
  messagingSenderId: "983569980918",
  appId: "1:983569980918:web:c63643d36e795586003bd7",
  measurementId: "G-GQVM8K51GR"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const SignupSectionAdmin = () => {
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;

      const adminQuery = await firebase.firestore().collection('AdminUp').doc(user.uid).get();
      if (adminQuery.exists) {
        const adminData = adminQuery.data();
        navigate(`/User/AdminApproval/Status/${adminData.uniqueId}/${user.uid}`);
      } else {
        const uniqueId = await generateUniqueId();
        await firebase.firestore().collection('AdminUp').doc(user.uid).set({
          fullName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uniqueId: uniqueId
        });
        navigate(`/User/AdminApproval/Status/${uniqueId}/${user.uid}`);
      }
    } catch (error) {
      console.error('Error logging in with Google:', error);
      alert('There was an error logging in. Please try again.');
    }
  };

  const generateUniqueId = async () => {
    let uniqueId;
    let idExists = true;
    while (idExists) {
      uniqueId = Math.floor(100000 + Math.random() * 900000);
      const idExistsQuery = await firebase
        .firestore()
        .collection('AdminUp')
        .where('uniqueId', '==', uniqueId)
        .get();
      idExists = !idExistsQuery.empty;
    }
    return uniqueId;
  };

  return (
    <section className="signup-section">
      <div className="container">
        <div className="signup-options">
          <div className="logo-container">
            <Link to='./'>
              <img src={logo} alt="LectureMeet Logo" />
            </Link>
          </div>
          <h2>Welcome to Lecturemeet Pre-Degree section</h2>
          <p>Create a new account or signin</p>
          <div className="signup-links">
            <button onClick={handleGoogleLogin}>Admin Login with Google</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupSectionAdmin;

