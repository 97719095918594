import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const OtpVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, uid, fullName } = location.state;
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleVerifyOtp = async () => {
    try {
      const otpDoc = await firebase.firestore().collection('otpverify').doc(uid).get();
      if (otpDoc.exists) {
        const otpData = otpDoc.data();
        if (otpData.otp === otp) {
          alert('OTP verified successfully!');
          navigate(`/User/Dashboard/${otpData.uniqueId}/${uid}`, { state: { fullName } });
        } else {
          setError('Invalid OTP. Please try again.');
        }
      } else {
        setError('OTP verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('There was an error verifying the OTP. Please try again.');
    }
  };

  return (
    <div>
      <h2>OTP Verification</h2>
      <p>Enter the OTP sent to your email: {email}</p>
      <input
        type="text"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Enter OTP"
      />
      <button onClick={handleVerifyOtp}>Verify OTP</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default OtpVerification;
