import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const SelectCourse = () => {
  const navigate = useNavigate();
  const { uniqueId, username } = useParams();
  const [loading, setLoading] = useState(true);
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const courses = [
    "Anti Money Laundering and CFT Compliance Officer",
    "Business Development and Risk Compliance Officer",
    "Banking and Financial Services Law",
    "Corporate Law Governance, and Regulatory Policies (CLG)",
    "Compliance in Payment Card Industry Data Security Standard (PCI DSS)",
    "Data Protection Regulation: GDPR, & Regional Compliance Officer",
    "Human Resource Management and Compliance",
    "Intellectual Property Law",
    "Insurance Law & Policy (Regulatory framework - Nigeria)",
    "Product Management",
    "Product Marketing Guide",
    "Mergers and Acquisition Manager",
    "Taxation Policy & Regulatory compliance",
  ];

  useEffect(() => {
    const checkAuth = async () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setCurrentUser(user);
          setLoading(false);
        } else {
          alert('You must be logged in to view this page.');
          navigate('/Account');
        }
      });
    };

    checkAuth();
  }, [navigate]);

  const handleCourseSelect = async (courseName) => {
    setLoading(true);
    setSelectedCourse(courseName);
    try {
      if (currentUser) {
        const userUID = currentUser.uid;
  
        // Check if user has paid for the course
        const paymentRef = firebase
          .firestore()
          .collection('Users-Data')
          .doc(userUID)
          .collection('PayPerCoursepaymentsHistory')
          .where('selectedCourse', '==', courseName)
          .where('status', '==', 'successful');
        const paymentSnapshot = await paymentRef.get();
  
        if (!paymentSnapshot.empty) {
          const courseRef = firebase
            .firestore()
            .collection('Users-Data')
            .doc(userUID)
            .collection('Courses')
            .doc(courseName);
          const courseSnapshot = await courseRef.get();
  
          if (courseSnapshot.exists) {
            await courseRef.update({
              dateCommenced: new Date().toISOString(),
            });
          } else {
            await courseRef.set({
              uniqueId: uniqueId,
              fullName: currentUser.displayName || 'Anonymous',
              courseName: courseName,
              dateCommenced: new Date().toISOString(),
              paymentHistory: [],
              progress: 0,
              otherDetails: {},
            });
          }
  
          navigate(`/User/Course/Course-board/${courseName}/${uniqueId}/${username}`);
        } else {
          setPaymentRequired(true);
        }
      } else {
        alert('You must be logged in to select a course.');
        navigate('/Account');
      }
    } catch (error) {
      console.error('Error selecting course:', error.message);
      alert('Error selecting course. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentRedirect = () => {
    navigate(`/User/Pay/${selectedCourse}/${uniqueId}/${username}`, {
      state: {
        fullName: currentUser.displayName || 'Anonymous',
        email: currentUser.email,
        selectedCourse: selectedCourse
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="select-course-container">
      <h1>Select a Course</h1>
      {paymentRequired && (
        <div className="payment-required">
          <p>You have not paid for the selected course. Please pay to access the course.</p>
          <button onClick={handlePaymentRedirect}>Pay for Course</button>
        </div>
      )}
      <div className="course-grid">
        {courses.map(course => (
          <div
            key={course}
            className="course-card"
            onClick={() => handleCourseSelect(course)}
          >
            {course}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCourse;
