import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const ViewDetailsPage = () => {
  const { applicantId } = useParams();  // Removed uniqueId
  const [applicantData, setApplicantData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplicantData = async () => {
      try {
        const doc = await firebase.firestore().collection('Pre-Degree-Users-Data').doc(applicantId).get();
        if (doc.exists) {
          setApplicantData(doc.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching applicant data:', error);
      }
    };

    fetchApplicantData();
  }, [applicantId]);

  return (
    <div className="view-details-container">
      <h1>Applicant Details</h1>
      {applicantData ? (
        <div>
          <p><strong>Name:</strong> {applicantData.name}</p>
          <p><strong>Email:</strong> {applicantData.email}</p>
          <p><strong>Unique ID:</strong> {applicantData.uniqueId}</p>
          {/* Add more details as needed */}
          <button onClick={() => navigate(-1)}>Back</button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ViewDetailsPage;
