import React from 'react';

const HeroPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.heroSection}>
        <h1 style={styles.header}>We Are Working!</h1>
        <p style={styles.subHeader}>This programme will soon launch. Check back later in October for updates.</p>
        <button style={styles.button} onClick={() => window.location.href = 'https://lecturemeet.com/programme'}>
          Explore other courses and programmes
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
  },
  heroSection: {
    maxWidth: '600px',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  header: {
    fontSize: '48px',
    color: '#333',
    margin: '0 0 20px',
  },
  subHeader: {
    fontSize: '24px',
    color: '#666',
    margin: '0 0 20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default HeroPage;
