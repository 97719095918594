import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const DashboardPage = () => {
  const { uniqueId, uid } = useParams();
  const { state } = useLocation();
  const fullName = state?.fullName || ''; // Provide a default value if fullName is null or undefined
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [hasPaid, setHasPaid] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      const userDoc = await firebase.firestore().collection('Pre-Degree-Users-Data').doc(uid).get();
      if (userDoc.exists) {
        setUserData(userDoc.data());
      } else {
        console.log('User data not found');
      }

      const paymentSnapshot = await firebase.firestore().collection('Pre-Degree-Users-Data').doc(uid).collection('Pre-degree-ApplicantPayment').get();
      setHasPaid(!paymentSnapshot.empty);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [uid]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      navigate('./');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(' ');
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-page">
      <h1>Welcome, {capitalizeFullName(fullName)}!</h1>
      <h2>Profile</h2>
      <p>Email: {userData.email}</p>
      <div className="profile-photo">
        <img src={userData.photoURL} alt="User" />
      </div>
      {hasPaid && <button><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-collection-play-fill" viewBox="0 0 16 16">
  <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437"/>
</svg>  Start Course</button>}
      <button onClick={() => navigate(`/User/Dashboard/Admission-status/${uniqueId}/${uid}`)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-bookmarks-fill" viewBox="0 0 16 16">
  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z"/>
  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z"/>
</svg> 
 Check Admission Status</button>
      <button onClick={handleLogout}><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
</svg> Logout</button>
    </div>
  );
};

export default DashboardPage;
